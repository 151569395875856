import React, { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { isAbsoluteUrl } from "../../utils/link";

const Url: React.FC<AnchorHTMLAttributes<HTMLAnchorElement> & { url: string }> =
  (props) => {
    const { url, ...htmlProps } = props;
    return isAbsoluteUrl(url) ? (
      <a {...htmlProps} href={url}>
        {htmlProps.children || url}
      </a>
    ) : (
      // @ts-ignore
      <Link {...htmlProps} to={url}>
        {htmlProps.children || url}
      </Link>
    );
  };

export default Url;
