import "object.assign/auto";
import "object.entries/auto";
import "object.values/auto";
import "es6-promise/auto";
import "ie-array-find-polyfill";

import React, { useState, useEffect } from "react";
import { Store } from "redux";
import { setAutoFreeze } from "immer";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { configureStore, history } from "./store";
import "moment/locale/de";

import Root from "./Root";

setAutoFreeze(false);

const App: React.FC = () => {
  const [store, setStore] = useState<Store | null>(null);

  useEffect(() => {
    const doConfigureStore = async () => {
      const store = await configureStore();
      const state = store.getState();
      setStore(store);
    };
    doConfigureStore().catch(console.error);
  }, []);

  if (!store) {
    return <div />;
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
