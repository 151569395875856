import React from "react";
// import Button from "antd/lib/button";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ActionProps } from "./selectors";

import icon from "../../assets/images/brand-logos/openchrom.png";
import screenshot from "../../assets/images/screenshots/openchrom.png";

const OpenChromBanner: React.FC<ActionProps> = (props) => {
  const onDownloadClick = () => {
    props.navigate("/download");
  };

  return (
    <div
      className=""
      style={{
        backgroundImage:
          "linear-gradient(to right, rgb(114, 114, 114), rgba(114, 114, 114, 0))",
      }}
    >
      <div className="container mx-auto flex flex-wrap justify-center items-center py-12">
        <div className="w-full md:w-5/12 lg:w-4/12 md:mr-8 lg:mr-12 mb-8 md:mb-0 px-6 flex flex-col items-center md:items-end">
          <img
            src={screenshot}
            alt="OpenChrom"
            className="banner-image img-shadow"
          />
        </div>

        <div
          className="w-full md:w-6/12 lg:w-4/12 flex flex-col justify-center items-center"
          style={{ color: "#484848" }}
        >
          <div className="text-3xl md:text-5xl font-bold leading-none text-center flex flex-row justify-center items-center">
            <img src={icon} alt="OpenChrom" className="mr-3" />
            <div>
              OpenChrom<sup>®</sup>
            </div>
          </div>
          <div className="text-2xl md:text-3xl font-bold leading-none text-center mt-2 md:mt-4">
            Data Analysis System
          </div>
          <Button
            className="mt-4 md:mt-6 btn-green"
            type="primary"
            size="large"
            onClick={onDownloadClick}
          >
            <DownloadOutlined />
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OpenChromBanner;
