import { Location } from "history";
import { createSelector } from "reselect";
import { AppState } from "../../reducers";
import { HeaderMenuItem } from "../../types/menu";
import { MENU_ITEMS } from "../../constants/menu";

export interface ContainerProps {
  location: Location;
}

export interface StateProps {
  menuItems: HeaderMenuItem[];
}

export interface ActionProps {
  setActiveMenu: (id: string, state: boolean) => void;
  navigate: (url: string) => void;
}
const selectHeader = (state: AppState, props: ContainerProps) => state.header;

export const makeSelectMenuItems = () =>
  createSelector([selectHeader], (header): HeaderMenuItem[] => {
    const headerMenuItems: HeaderMenuItem[] = MENU_ITEMS.map((parent) => ({
      key: parent.key,
      title: parent.title,
      url: parent.url,
      isActive: false,
      children: !parent.children
        ? undefined
        : parent.children.map((child) => ({
            key: child.key,
            title: child.title,
            url: child.url,
            parent: parent.key,
            isActive: false,
          })),
    }));

    headerMenuItems.forEach((parent) => {
      parent.isActive =
        !!header.activeMenuIds[parent.key] ||
        (!!parent.parent && !!header.activeMenuIds[parent.parent]);
      if (parent.children) {
        parent.children.forEach((child) => {
          child.isActive = !!header.activeMenuIds[child.key];
        });
      }
    });

    return headerMenuItems;
  });
