import { Dispatch, ThunkResult } from "../../reducers";
import * as MarketplaceService from "../../services/marketplace";
import {
  Download,
  GetMyDownloadListParams,
  HashSize,
} from "../../types/download";

export const UPDATE_MY_DOWNLOADS = "Marketplace.UPDATE_MY_DOWNLOADS";
export const UPDATE_MY_DOWNLOAD = "Marketplace.UPDATE_MY_DOWNLOAD";
export const UPDATE_MY_DOWNLOAD_HASH_SIZE =
  "Marketplace.UPDATE_MY_DOWNLOAD_HASH_SIZE";
export const REMOVE_MY_DOWNLOAD = "Marketplace.REMOVE_MY_DOWNLOAD";
export const UPDATE_MY_PURCHASES = "Marketplace.UPDATE_MY_PURCHASES";
export const UPDATE_MY_DEMOS = "Marketplace.UPDATE_MY_DEMOS";

interface UpdateMyDownloadsAction {
  type: typeof UPDATE_MY_DOWNLOADS;
  downloads: Download[];
}

interface UpdateMyDownloadAction {
  type: typeof UPDATE_MY_DOWNLOAD;
  download: Download;
}

interface RemoveMyDownloadAction {
  type: typeof REMOVE_MY_DOWNLOAD;
  id: Download["id"];
}

interface UpdateHashSizeAction {
  type: typeof UPDATE_MY_DOWNLOAD_HASH_SIZE;
  hashSizes: HashSize[];
}

export type MarketplaceAction =
  | UpdateMyDownloadsAction
  | UpdateMyDownloadAction
  | RemoveMyDownloadAction
  | UpdateHashSizeAction;

export const updateLocalMyDownloads = (
  downloads: Download[],
): UpdateMyDownloadsAction => ({
  type: UPDATE_MY_DOWNLOADS,
  downloads,
});

export const updateLocalMyDownload = (
  download: Download,
): UpdateMyDownloadAction => ({
  type: UPDATE_MY_DOWNLOAD,
  download,
});

export const removeLocalMyDownload = (
  id: Download["id"],
): RemoveMyDownloadAction => ({
  type: REMOVE_MY_DOWNLOAD,
  id,
});

export const updateLocalHashSize = (
  hashSizes: HashSize[],
): UpdateHashSizeAction => ({
  type: UPDATE_MY_DOWNLOAD_HASH_SIZE,
  hashSizes,
});

export const getMyDownloads =
  (
    params: GetMyDownloadListParams,
  ): ThunkResult<Promise<{ total: number; items: Download[] }>> =>
  async (dispatch, getState) => {
    const { total, items } = await MarketplaceService.getMyDownloads(params);
    dispatch(updateLocalMyDownloads(items));
    return { total, items };
  };

export const getMyDownload =
  (id: Download["id"]): ThunkResult<Promise<Download>> =>
  async (dispatch, getState) => {
    try {
      const download = await MarketplaceService.getMyDownload(id);
      dispatch(updateLocalMyDownload(download));
      return download;
    } catch (err) {
      dispatch(removeLocalMyDownload(id));
      throw err;
    }
  };

export const updateHashSize =
  (version: string): ThunkResult<Promise<boolean>> =>
  async (dispatch, getState) => {
    return MarketplaceService.updateHashSize(version);
  };

export const getHashSize =
  (): ThunkResult<Promise<{ total: number; items: HashSize[] }>> =>
  async (dispatch, getState) => {
    const { total, items } = await MarketplaceService.getHashSize();
    dispatch(updateLocalHashSize(items));
    return { total, items };
  };
