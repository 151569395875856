export enum OS {
  None = "",
  Windows = "Windows",
  Linux = "Linux",
  MacOS = "Mac OS",
}

export const OS_MAPPING = {
  [OS.Windows]: ["Windows"],
  [OS.MacOS]: ["Mac OS", "Unix"],
  [OS.Linux]: [
    "Linux",
    "CentOS",
    "Fedora",
    "Debian",
    "Mandriva",
    "Mint",
    "RedHat",
    "SUSE",
    "Ubuntu",
  ],
};

export const DOWNLOAD_OS_MAPPING = {
  [OS.Windows]: "win32",
  [OS.MacOS]: "macosx",
  [OS.Linux]: "linux",
};

export const DOWNLOAD_PACKAGING_MAPPING = {
  [OS.Windows]: "zip",
  [OS.MacOS]: "tar.gz",
  [OS.Linux]: "tar.gz",
};

export const DOWNLOAD_WS_MAPPING = {
  [OS.Windows]: "win32",
  [OS.MacOS]: "cocoa",
  [OS.Linux]: "gtk",
};

export const DOWNLOAD_PLATFORM_MAPPING = {
  [OS.Windows]: "x86_64",
  [OS.MacOS]: "x86_64",
  [OS.Linux]: "x86_64",
};

// export const OPENCHROM_DOWNLOAD_LINKS: { [os: string]: string } = {
//   [OS.MacOS]:
//     'https://products.lablicate.com/openchrom/1.4.x/openchrom-lablicate_macosx.x86_64_1.4.x.tar.gz',
//   [OS.Linux]:
//     'https://products.lablicate.com/openchrom/1.4.x/openchrom-lablicate_linux.x86_64_1.4.x.tar.gz',
//   [OS.Windows]:
//     'https://products.lablicate.com/openchrom/1.4.x/openchrom-lablicate_win32.x86_64_1.4.x.zip',
// };
export const OPENCHROM_MAC_FILE_TEMPLATE = "OpenChrom_{version}_x86_64.dmg";

export const OPENCHROM_FILE_TEMPLATE =
  "openchrom-lablicate_{os}.x86_64_{version}.{packaging}";

export const OPENCHROM_WINDOWS_FILE_TEMPLATE = "OpenChrom_{version}_{type}_x64.exe";

export const OPENCHROM_FILE_NAME: { [os: string]: string } = {
  [OS.MacOS]: "openchrom-lablicate_macosx.x86_64_1.4.x.tar.gz",
  [OS.Linux]: "openchrom-lablicate_linux.x86_64_1.4.x.tar.gz",
  [OS.Windows]: "openchrom-lablicate_win32.x86_64_1.4.x.zip",
};

export const WEBISTE_API_ENDPOINT = process.env.REACT_APP_WEBISTE_API_ENDPOINT;

export const MARKETPLACE_ENDPOINT = process.env.REACT_APP_MARKETPLACE_ENDPOINT;

export const MARKETPLACE_PUBLIC_ASSETS_ENDPOINT =
  process.env.REACT_APP_MARKETPLACE_PUBLIC_ASSETS_ENDPOINT;

// export const OPENCHROM_FILE_SIZE: { [os: string]: string } = {
//   [OS.MacOS]: '353MB',
//   [OS.Linux]: '359MB',
//   [OS.Windows]: '353MB',
// };

// export const OPENCHROM_FILE_CHECKSUM: { [os: string]: string } = {
//   [OS.MacOS]:
//     'c67eee0ca6f5bf451e8bfe3e39ef038508102f2a9377b0751a4fc6573d6d66dd',
//   [OS.Linux]:
//     '67f497663c0361a8cc93303935409a4e2040a485a2fcfebe26af6a010467c796',
//   [OS.Windows]:
//     '7d8c96cdc2bdd10085213bfad6d238a307a8ea86d3235bd5b327d0b92cafccad',
// };
