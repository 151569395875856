import React, { AnchorHTMLAttributes } from "react";
import classNames from "classnames";
import Url from "../Url";

const OpenChromLinkableText: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> =
  (props) => {
    return (
      <Url
        {...props}
        className={classNames(props.className, "link font-semibold")}
        url="https://lablicate.com/platform/openchrom"
      >
        OpenChrom<sup>®</sup>
      </Url>
    );
  };

export default OpenChromLinkableText;
