import React from "react"
import MediaQuery from "react-responsive"
import Divider from "antd/lib/divider"
import "./Footer.css"
import Url from "../../components/Url";
import Brands from "../../components/Brands";

interface Props {
}

const Footer: React.FC<Props> = (props) => (
  <div className="text-white">
    <div className="w-full" style={{ backgroundColor: "#f8f8f8" }}>
      <Brands />
    </div>
    <div className="text-center whitespace-no-wrap py-4" style={{ backgroundColor: "#1a1a1a" }}>
      <span>Copyright © {new Date().getFullYear()} Lablicate GmbH</span>

      <MediaQuery minWidth={640}>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/contact" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Contact
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/imprint" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Imprint
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/privacy" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Privacy Policy
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/terms" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Terms & Conditions
        </Url>
      </MediaQuery>

      <MediaQuery maxWidth={639}>
        <br />
        <Url url="https://lablicate.com/about/contact" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Contact
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/imprint" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Imprint
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/privacy" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Privacy Policy
        </Url>
        <Divider type="vertical" />
        <Url url="https://lablicate.com/about/terms" className="text-white hover:text-white hover:underline whitespace-no-wrap">
          Terms & Conditions
        </Url>
      </MediaQuery>

    </div>

  </div>
);

export default Footer
