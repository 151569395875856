export enum DownloadAccess {
  Public = "public",
  Registered = "registered",
  Restricted = "restricted",
}

export interface DownloadElement {
  id: number;
  url: string;
  version: string;
  isCurrent: boolean;
}

export interface Download {
  id: number;
  category: "Product" | "Method" | "Generic";
  access: DownloadAccess;
  visibleInApiOnly: boolean;
  title: string;
  description: string;
  order: number;
  screenshots: File[];
  elements: DownloadElement[];
}
export interface HashSize {
  id: number;
  name: string;
  size: string;
  hash: string;
}

export interface GetMyDownloadListParams {
  page: number;
  pageSize: number;
  sort?: {
    field: string;
    order: "asc" | "desc";
  };
  searchText: string;
}

export enum DownloadCategory {
  Product = 'Product',
  Method = 'Method',
  Generic = 'Generic',
}
