import { Action, combineReducers, Reducer } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import headerReducer from "./components/Header/reducer";
// import viewReducer from "containers/App/View/reducer"
// import userReducer from "containers/App/User/reducer"
import marketplaceReducer from "./containers/marketplace/reducer";
// import marketplacePluginsReducer from "containers/Marketplace/Plugins/reducer"
// import marketplaceMyDownloadsReducer from "containers/Marketplace/MyDownloads/reducer"

const createAppReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    header: headerReducer,
    // view: viewReducer,
    // user: userReducer,
    marketplace: marketplaceReducer,
    // marketplacePlugins: marketplacePluginsReducer,
    // marketplaceMyDownloads: marketplaceMyDownloadsReducer,
  });

export type AppState = ReturnType<ReturnType<typeof createAppReducer>>;

export type ThunkResult<R> = ThunkAction<R, AppState, void, Action>;

export type Dispatch = ThunkDispatch<AppState, void, Action>;

export default (history: History): Reducer => createAppReducer(history);
