import { faCheckDouble, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, notification } from "antd";
import React from "react";
import MarketplaceButton from "../../components/MarketplaceButton";
import NotificationSHA256 from "../../components/NotificationSHA256";
import {
  DOWNLOAD_OS_MAPPING,
  DOWNLOAD_PACKAGING_MAPPING,
  OPENCHROM_FILE_TEMPLATE,
  OPENCHROM_MAC_FILE_TEMPLATE,
  OS,
} from "../../constants/links";
import { HashSize } from "../../types/download";
import { getDownloadAarchLink, getDownloadLinkByOS } from "../../utils/link";

interface Props {
  hashSizes: HashSize[];
  version: string;
  url: string;
}

const OpenChromDownloadMacOS: React.FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const getFileSize = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.size : "";
  };

  const getFileHash = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.hash : "";
  };

  const getFileName = () => {
    if (props.version === "1.4.x") {
      return OPENCHROM_FILE_TEMPLATE.replace(
        /{os}/g,
        DOWNLOAD_OS_MAPPING[OS.MacOS],
      )
        .replace(/{version}/g, props.version)
        .replace(/{packaging}/g, DOWNLOAD_PACKAGING_MAPPING[OS.MacOS]);
    }
    return OPENCHROM_MAC_FILE_TEMPLATE
      .replace(/{version}/g, props.version);
  };

  const getAarchFileName = () => {
    return (getFileName() || "").replace("x86_64", "aarch64");
  };

  const openDownload = async () => {
    api.open({
      message: `SHA256 Hash for ${getFileName()}`,
      icon: <FontAwesomeIcon icon={faCheckDouble} />,
      description: <NotificationSHA256 hash={getFileHash(getFileName())} />,
      duration: 0
    });

    let url = getDownloadLinkByOS(props.url, OS.MacOS);
    if (props.version !== "1.4.x") {
      url = url.replace(/openchrom-.*/g, `OpenChrom_${props.version}_x86_64.dmg`);
    }
    window.open(url, '_blank');
  };

  const openDownloadAarch = async () => {
    api.open({
      message: `SHA256 Hash for ${getAarchFileName()}`,
      icon: <FontAwesomeIcon icon={faCheckDouble} />,
      description: <NotificationSHA256 hash={getFileHash(getAarchFileName())} />,
      duration: 0
    });

    window.open(getDownloadAarchLink(props.url, props.version), '_blank');
  };

  return (
    // <div className="download-area h-auto p-4">
    <>
      {contextHolder}
      <div className="flex flex-col lg:flex-row justify-around">
        <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
          <p className="overflow-wrap-breakword flex lg:justify-center">
            OpenChrom works on most computers running recent Mac OS.
          </p>
          <div className="flex flex-col">
            <div className="ant-btn-group btn-download h-auto lg:m-4 m-1 text-lg justify-center">
              <Button
                type="default"
                key="k1"
                className="shadow text-lg flex h-auto p-4 mr-2"
                style={{ color: "white" }}
                onClick={() => openDownload()}
              >
                <div>
                  <span className="font-semibold">Download Intel64 build</span>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">File name:</span>{" "}
                    <span>{getFileName()}</span>
                  </div>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">Size:</span>{" "}
                    <span>{getFileSize(getFileName())}</span>
                  </div>
                </div>
                <div className="my-auto ml-4">
                  <FontAwesomeIcon size="2x" icon={faDownload} />
                </div>
              </Button>
              {/* <MarketplaceButton /> */}
            </div>
          </div>
        </div>
        {!!getFileHash(getAarchFileName()) && (
          <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
            <p className="overflow-wrap-breakword flex lg:justify-center">
              OpenChrom is also available for ARM64.
            </p>
            <div className="flex justify-center">
              <div className="ant-btn-group btn-download h-auto lg:m-4 m-1 text-lg">
                <Button
                  type="default"
                  key="k1"
                  className="shadow text-lg flex h-auto p-4"
                  style={{ color: "white" }}
                  onClick={() => openDownloadAarch()}
                >
                  <div>
                    <span className="font-semibold">Download ARM64 build</span>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">File name:</span>{" "}
                      <span>{getAarchFileName()}</span>
                    </div>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">Size:</span>{" "}
                      <span>{getFileSize(getAarchFileName())}</span>
                    </div>
                  </div>
                  <div className="my-auto ml-4">
                    <FontAwesomeIcon size="2x" icon={faDownload} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
    // </div>
  );
};

export default OpenChromDownloadMacOS;
