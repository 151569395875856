import { createTransform } from "redux-persist";
import traverse from "traverse"
import moment from "moment"

export const dateTransform = createTransform(
  (inboundState: any, key) => inboundState,
  (outboundState: any, key) => {
    return traverse(outboundState).map((val: any) => {
      if (moment(val, moment.ISO_8601, true).isValid()) {
        return new Date(val);
      }
      return val;
    });
  },
);
