import produce from "immer";
import { HeaderAction, SET_ACTIVE_MENU } from "./actions";

export interface HeaderState {
  readonly activeMenuIds: { [id: string]: boolean };
}

const initialState: HeaderState = {
  activeMenuIds: {},
};

const reducer = (
  state: HeaderState = initialState,
  action: HeaderAction,
): HeaderState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ACTIVE_MENU:
        draft.activeMenuIds[action.id] = action.state;
        return;
    }
  });

export default reducer;
