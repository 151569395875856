import { applyMiddleware, compose, createStore, Store } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createReducer, { AppState } from "./reducers";

// Create a history of your choosing (we are using a browser history in this case)
export const history = createBrowserHistory();

export const configureStore = async (): Promise<Store<AppState>> =>
  new Promise((resolve) => {
    const isDebugging: boolean = process.env.NODE_ENV === "development";

    const appStore = createStore(
      createReducer(history),
      undefined,
      compose(
        applyMiddleware(thunk),
        applyMiddleware(routerMiddleware(history)), // for dispatching history actions
      ),
    );

    persistStore(appStore, undefined, () => {
      if (isDebugging) {
        // @ts-ignore
        window.store = appStore;
      }
      resolve(appStore);
    });
  });
