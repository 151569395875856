import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import classNames from "classnames";
import includes from "lodash/includes";
import startsWith from "lodash/startsWith";
import { Menu, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { ContainerProps, StateProps, ActionProps } from "./selectors";
import "./Header.css";
import Url from "../Url";

import logo from "../../assets/images/logo.svg";
import { HeaderMenuItem } from "../../types/menu";
import Sticky from "react-stickynode";

const { SubMenu } = Menu;

interface Props extends ContainerProps, StateProps, ActionProps { }

const handleMenuItemClick = (props: Props, { key }: { key: string }) => {
  if ("nist23" === key) {
    props.navigate("https://lablicate.com/services/nist23");
  }
};

const DesktopMenu: React.FC<Props> = (props) => {
  const selectedKeys = props.menuItems
    .filter((parent) => parent.isActive)
    .map((parent) => parent.key);

  return (
    <div className="w-10/12 flex">
      <div className="w-10/12">
        <Menu
          mode="horizontal"
          selectedKeys={selectedKeys}
          onClick={(e) => handleMenuItemClick(props, e)}
        >
          {props.menuItems.map((parent) => (
            <Menu.Item key={parent.key} className="text-lg font-semibold">
              {parent.title}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

const MobileMenu: React.FC<Props> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className="flex flex-col justify-center items-center">
      <Button onClick={() => setVisible(true)}>
        <MenuOutlined className="text-lg align-middle" />
      </Button>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        className="app-menu"
      >
        <Menu
          mode="inline"
          onClick={(e) => {
            setVisible(false);
            handleMenuItemClick(props, e);
          }}
        >
          {props.menuItems.map((parent) =>
            !parent.children || parent.children.length === 0 ? (
              <Menu.Item key={parent.key} className="text-base font-semibold">
                {parent.title}
              </Menu.Item>
            ) : (
              <SubMenu
                key={parent.key}
                title={<span className="font-medium">{parent.title}</span>}
                onTitleClick={(e) => {
                  handleMenuItemClick(props, e);
                }}
              >
                {parent.children.map((child) => (
                  <Menu.Item key={child.key} className="text-base font-medium">
                    {child.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ),
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

const Header: React.FC<Props> = (props) => {
  useEffect(() => {
    props.menuItems.forEach((parent) => {
      if (
        parent.url &&
        (!parent.exactMatch
          ? startsWith(props.location.pathname, parent.url)
          : props.location.pathname === parent.url)
      ) {
        if (!parent.isActive) props.setActiveMenu(parent.key, true);
      } else {
        if (parent.isActive) props.setActiveMenu(parent.key, false);
      }
      if (parent.children) {
        parent.children.forEach((child) => {
          if (
            child.url &&
            (!child.exactMatch
              ? startsWith(props.location.pathname, child.url)
              : props.location.pathname === child.url)
          ) {
            if (!child.isActive) props.setActiveMenu(child.key, true);
          } else {
            if (child.isActive) props.setActiveMenu(child.key, false);
          }
        });
      }
    });
  });

  const selectedParent = props.menuItems.find((parent) => {
    let selected = false;
    if (parent.isActive) {
      selected = true;
    } else if (parent.children) {
      parent.children.forEach((child) => {
        if (child.isActive) selected = true;
      });
    }
    return selected;
  });

  let showSubmenu = false;
  let childrenOfSelectedParent: HeaderMenuItem[] = [];
  if (
    selectedParent &&
    selectedParent.children &&
    selectedParent.children.length > 0
  ) {
    childrenOfSelectedParent = selectedParent.children.filter(
      (child) => !includes([""], child.key),
    );
    if (childrenOfSelectedParent.length > 0) {
      showSubmenu = true;
    }
  }

  return (
    <React.Fragment>
      <div className={classNames("app-menu", { "border-b border-gray-200": !showSubmenu, })}>
        <div className="container mx-auto px-4 md:px-8 lg:px-12 flex flex-row items-center">
          <Url
            className="w-8/12 sm:w-4/12 md:w-4/12 lg:w-2/12 xl:w-2/12 mr-8 py-3 h-18 flex flex-col justify-center items-end"
            url="https://lablicate.com"
          >
            <img src={logo} alt="" className="h-12" />
          </Url>
          <MediaQuery minWidth={1023}>
            <div className="text-xl">OpenChrom<sup>®</sup> - the open source software for chromatography, spectrometry and spectroscopy</div>
          </MediaQuery>
        </div>
      </div>
      {/* <div className="app-submenu w-full h-12">
        <div className="w-full container mx-auto px-4 md:px-8 lg:px-12 flex flex-row justify-between items-center">
        </div>
      </div> */}
      <MediaQuery minWidth={1}>
        <Sticky enabled={true} top={0} bottomBoundary={0} innerZ={999}>
          <div className="app-submenu w-full h-12">
            <div className="w-full container mx-auto md:w-8/12 flex flex-row justify-end items-center h-full">
              <div className="text-white text-base font-semibold border-b-4 border-white cursor-pointer flex items-center h-full mx-4 md:mx-0"
                onClick={e => handleMenuItemClick(props, { key: "nist23" })}
              >
                NIST 23 - order the new version now
              </div>
            </div>
          </div>
        </Sticky>
      </MediaQuery>
    </React.Fragment >
  );
};

export default Header;
