import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import OpenChromBanner from "../../components/Banner";
import OpenChromLinkableText from "../../components/OpenChromLinkableText";
import ChromIdentLinkableText from "../../components/ChromIdentLinkableText";
import LablicateLinkableText from "../../components/LablicateLinkableText";
import { Helmet } from "react-helmet";
import ImageSlider from "../../components/ImageSlider";
import "./Home.css";
import { AppState, Dispatch } from "../../reducers";
import { isAbsoluteUrl } from "../../utils/link";
import { ActionProps, ContainerProps, StateProps } from "./selectors";

import screenshot1 from "../../assets/images/screenshots/openchrom-GC-MS.png";
import screenshot2 from "../../assets/images/screenshots/openchrom-GC-MS-MS.png";
import screenshot3 from "../../assets/images/screenshots/openchrom-PCR.png";
import screenshot4 from "../../assets/images/screenshots/openchrom-NMR-1.png";
import screenshot5 from "../../assets/images/screenshots/openchrom-NMR-2.png";
import screenshot6 from "../../assets/images/screenshots/openchrom-FTIR.png";
import { push } from "connected-react-router";

const screenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5,
  screenshot6,
];

interface Props extends ActionProps { }

const Home: React.FC<Props> = (props) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(-1);
  return (
    <div>
      <Helmet>
        <title> OpenChrom by Lablicate </title>
        <meta
          name="description"
          content="Download the latest version of OpenChrom - Open Source Software for Chromatography and Spectroscopy. Available for Windows, macOS and Linux."
        />
      </Helmet>
      <OpenChromBanner navigate={props.navigate} />

      <ImageSlider
        images={screenshots}
        selectedIndex={selectedPhotoIndex}
        setSelectedIndex={setSelectedPhotoIndex}
      />

      <div className="container mx-auto px-4 md:px-8 lg:px-12 py-6">
        {/* Add h1 tag for SEO */}
        <h1 className="hidden"> Lablicate Openchrom </h1>
        <p className="py-3 text-lg text-justify">
          <OpenChromLinkableText /> is an open source software for{" "}
          <span className="font-semibold">
            chromatography, spectrometry and spectroscopy
          </span>
          . Data from different systems can be imported and analyzed, hence it’s
          a vendor independent software. Moreover, it runs under{" "}
          <span className="font-semibold">Windows, macOS and Linux</span>. So
          the user has the choice to work wherever he wants, independent of the
          system. Originally, the intention was to create a{" "}
          <span className="font-semibold">ChemStation alternative</span>.
          Meanwhile, it has become much more. Generally, it supports to handle{" "}
          <span className="font-semibold">
            GC/MS, GC/FID, HPLC-UV/VIS, FTIR, PCR and NMR
          </span>{" "}
          data. Originally, <OpenChromLinkableText /> was designed to analyze
          data from analytical pyrolysis, called{" "}
          <span className="font-semibold">Py-GC/MS</span>. Thus, it has a strong
          focus on chromatography and nominal mass spectrometry. In the course
          of time, additional requirements have been added. More features are
          already in the pipeline. Its strength is to handle{" "}
          <span className="font-semibold">GC/MS and GC/FID</span> measurements.
          Methods for peak detection, integration, identification, quantitation
          and reporting are supported. Using internal (
          <span className="font-semibold">ISTD</span>) and external standards (
          <span className="font-semibold">ESTD</span>) for quantitation purposes
          is supported as well. Additional filter help to optimize the
          measurements and classifier calculate key values of the
          chromatographic data and help to point out problems like shifted
          retention times or degraded columns. Did you know that it is easy to{" "}
          <span className="font-semibold">detect and identify peaks</span> in a
          GC/MS file and to transfer these peaks to its corresponding GC/FID
          measurement for <span className="font-semibold">quantitation</span>{" "}
          purposes. In summary, the modularity of <OpenChromLinkableText />{" "}
          allows to recombine the contained functionality for many different
          purposes. Both working in target screening (
          <span className="font-semibold">TS</span>) and non-target screening (
          <span className="font-semibold">NTS</span>) mode is possible.
          Therefore, the platform can be utilized for{" "}
          <span className="font-semibold">quality control</span> purposes or for
          the analysis of{" "}
          <span className="font-semibold">chromatographic fingerprints</span>.
        </p>

        <p className="py-3 text-lg text-justify">
          A typical workflow consists of the following steps:
        </p>

        <ul className="pb-3 text-lg list-disc list-inside font-medium">
          <li>Load chromatogram [MSD]</li>
          <li>Filter and optimize the data (optionally)</li>
          <li>Detect and integrate peaks</li>
          <li>Identify peaks (NIST-DB, user specific databases)</li>
          <li>Transfer peaks to chromatogram [FID]</li>
          <li>Set internal standard(s)</li>
          <li>Quantify peaks</li>
          <li>Report results</li>
        </ul>

        <p className="py-3 text-lg text-justify">
          All these steps can be applied manually. It’s recommended to run the
          analysis of the data a few times by hand. But{" "}
          <OpenChromLinkableText /> also supports{" "}
          <span className="font-semibold">process methods</span> and{" "}
          <span className="font-semibold">batch processes</span>. The
          aforementioned steps can be automated and customized in a very
          flexible way.
        </p>

        <p className="py-3 text-lg text-justify">
          Extensions can be easily added. <LablicateLinkableText /> offers a
          commercial extension called <ChromIdentLinkableText />. This tool
          allows to add chromatographic peaks as a whole to a database. While
          adding chromatographic peaks to the database, typical peak patterns
          are detected and automatically extracted into a composite peak table.
          This peak table is used for identification purposes of unknown
          samples. By using the peak patterns, it possible to tell apart complex
          mixtures and their composition.
        </p>

        <div className="py-3 flex flex-wrap justify-center items-center -mx-4">
          {screenshots.map((screenshot, index) => (
            <div key={index} className="w-full md:w-1/3 p-4">
              <img
                src={screenshot}
                alt=""
                className="w-full h-auto block cursor-zoom-in img-shadow"
                onClick={() => setSelectedPhotoIndex(index)}
              />
            </div>
          ))}
        </div>


        <p className="py-3 text-lg text-justify">
          List of supported data formats:
        </p>

        <ul className="pb-3 text-base list-disc list-inside font-medium">
          <li>Agilent ChemStation FID (*.D/ *.ch)</li>
          <li>Thermo/Finnigan FID (*.dat)</li>
          <li>Thermo/Finnigan FID (*.raw)</li>
          <li>MassHunter (*.D/CPDET1A.CH)</li>
          <li>PerkinElmer FID (*.raw)</li>
          <li>Shimadzu GC10 FID (*.C0#)</li>
          <li>Shimadzu FID (*.gcd)</li>
          <li>Varian FID (*.run)</li>
          <li>Agilent MSD (*.D/DATA.MS)</li>
          <li>Agilent MSD (*.D/data01.icp)</li>
          <li>Bruker Flex MSD (fid)</li>
          <li>ChromTech MSD (*.dat)</li>
          <li>Thermo/Finnigan MSD (*.cgm)</li>
          <li>Thermo/Finnigan Element II (*.dat)</li>
          <li>Thermo/Finnigan ICIS (*.dat)</li>
          <li>Thermo/Finnigan ITDS (*.DAT)</li>
          <li>Thermo/Finnigan ITS40 (*.MS)</li>
          <li>Thermo/Finnigan MAT (*.dat)</li>
          <li>Thermo/Finnigan RAW MSD (*.raw)</li>
          <li>MassHunter MSD (*.D/AcqData)</li>
          <li>NIST Database MSD (*.db)</li>
          <li>Shimadzu MSD (*.qgd)</li>
          <li>Shimadzu QP-5000 MSD (*.R##)</li>
          <li>Shimadzu Database MSD (*.spc)</li>
          <li>Bruker/Varian MSD (*.SMS)</li>
          <li>Bruker/Varian MSD (*.XMS)</li>
          <li>VG MSD (*.DAT_001)</li>
          <li>Waters MSD (*.RAW/_FUNC001.DAT)</li>
          <li>ABSciex DAD (*.wiff)</li>
          <li>Agilent VWD (*.D/vwd1A.ch)</li>
          <li>CAMAG VWD (*.DFM)</li>
          <li>DataApex FID (*.PRM)</li>
          <li>MassFinder FID/MSD (*.mfg)</li>
          <li>MassFinder Library (*.mfl)</li>
          <li>ABSciex Reports (*.txt)</li>
          <li>EZChrom Reports (*.dat)</li>
          <li>ChemStation Reports (*.ch)</li>
          <li>&hellip;</li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  ContainerProps,
  StateProps
>({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigate: (url: string) => {
        if (isAbsoluteUrl(url)) {
          setTimeout(() => (window.location.href = url), 100);
          return { type: "" };
        }
        return push(url);
      },
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
