import { message } from "antd";
import React from "react";

interface Props {
  hash: string;
}

const NotificationSHA256: React.FC<Props> = (props) => {

  const copyToClipboard = async (fileHash: string) => {
    message.success("SHA256 hash has been copied to clipboard");
    return navigator.clipboard.writeText(fileHash);
  }

  return (
    <div className="mt-2">
      <div
        role="button"
        tabIndex={0}
        title="Click to copy"
        className="cursor-copy break-all"
        onClick={() => copyToClipboard(props.hash)}>{props.hash}
      </div>
    </div>
  );
};

export default NotificationSHA256;
