import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import produce from "immer";
import { dateTransform } from "../../utils/redux";
import {
  MarketplaceAction,
  UPDATE_MY_DOWNLOADS,
  UPDATE_MY_DOWNLOAD,
  REMOVE_MY_DOWNLOAD,
  UPDATE_MY_DOWNLOAD_HASH_SIZE,
} from "./actions";
import { Download, HashSize } from "../../types/download";
import "antd/dist/antd.min.css";

export interface MarketplaceState {
  myDownloads: { [key: number]: Download };
  hashSizes: { [key: number]: HashSize };
}

const initialState: MarketplaceState = {
  myDownloads: {},
  hashSizes: [],
};

const reducer = (
  state: MarketplaceState = initialState,
  action: MarketplaceAction,
): MarketplaceState =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_MY_DOWNLOADS:
        draft.myDownloads = action.downloads.reduce<{
          [key: number]: Download;
        }>((accumulator, download) => {
          download.elements = download.elements.sort((a, b) =>
            a.isCurrent
              ? -1
              : b.isCurrent
              ? 1
              : b.version.localeCompare(a.version),
          );
          accumulator[download.id] = download;
          return accumulator;
        }, {});
        return;
      case UPDATE_MY_DOWNLOAD:
        draft.myDownloads[action.download.id] = action.download;
        return;
      case REMOVE_MY_DOWNLOAD:
        delete draft.myDownloads[action.id];
        return;
      case UPDATE_MY_DOWNLOAD_HASH_SIZE:
        draft.hashSizes = action.hashSizes;
        return;
    }
  });

const persistConfig: PersistConfig = {
  key: "marketplace",
  storage,
  transforms: [dateTransform],
  blacklist: [],
};

export default persistReducer(persistConfig, reducer);
