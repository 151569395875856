export const SET_ACTIVE_MENU = "Header.SET_ACTIVE_MENU";

interface SetActiveMenuAction {
  type: typeof SET_ACTIVE_MENU;
  id: string;
  state: boolean;
}

export type HeaderAction = SetActiveMenuAction;

export const setActiveMenu = (
  id: string,
  state: boolean,
): SetActiveMenuAction => ({
  type: SET_ACTIVE_MENU,
  id,
  state,
});
