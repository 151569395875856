import { sendMarketplaceJsonRequest } from "../utils/net";
import { Download, GetMyDownloadListParams, HashSize } from "../types/download";
import { MARKETPLACE_PUBLIC_ASSETS_ENDPOINT } from "../constants/links";

export const getMyDownloads = async (
  params: GetMyDownloadListParams,
): Promise<{ total: number; items: Download[] }> => {
  let url = `/api/downloads?target=website&page=${params.page}&page_size=${params.pageSize}&search_key=${params.searchText}`;
  if (params.sort) {
    url += `&order_column=${params.sort.field}&order_direction=${params.sort.order}`;
  }
  const res = await sendMarketplaceJsonRequest({
    url,
    method: "GET",
  });
  const total = res.total;
  const items = res.items || [];

  return {
    total,
    items: items.map((item: any) => parseDownloadResponse(item)),
  };
};

export const getMyDownload = async (id: Download["id"]): Promise<Download> => {
  const item = await sendMarketplaceJsonRequest({
    url: `/api/downloads/${id}?target=website`,
    method: "GET",
  });
  return parseDownloadResponse(item);
};

const parseDownloadResponse = (item: any): Download => ({
  id: item.id,
  category: item.category,
  access: item.access,
  visibleInApiOnly: !!item.visible_in_api_only,
  title: item.title,
  description: item.description,
  order: item.order,
  screenshots: (item.screenshots || []).map((screenshot: any) => ({
    id: screenshot.media.id,
    fileName: screenshot.media.file_name,
    fileType: screenshot.media.file_type,
    filePath: `${MARKETPLACE_PUBLIC_ASSETS_ENDPOINT}/${screenshot.media.file_path}`,
    order: screenshot.order,
  })),
  elements: (item.elements || []).map((element: any) => ({
    id: element.id,
    url: element.url,
    version: element.version,
    isCurrent: !!element.is_current,
  })),
});

export const updateHashSize = async (version: string): Promise<boolean> => {
  return sendMarketplaceJsonRequest({
    url: `/api/downloads/hash-size`,
    method: "POST",
    body: { version: version },
  });
};

export const getHashSize = async (): Promise<{
  total: number;
  items: HashSize[];
}> => {
  const res = await sendMarketplaceJsonRequest({
    url: `/api/downloads/hash-size`,
    method: "GET",
    body: {},
  });
  const total = res.total;
  const items = res.items || [];

  return {
    total,
    items: items.map((item: any) => parseHashSizeResponse(item)),
  };
};

const parseHashSizeResponse = (item: any): HashSize => ({
  id: item.id,
  name: item.name,
  size: item.size,
  hash: item.hash,
});
