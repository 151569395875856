import axios from "axios";
import { ErrorCode, ApiError, NetworkError } from "../errors";
import { WEBISTE_API_ENDPOINT, MARKETPLACE_ENDPOINT } from "../constants/links";

export interface JsonRequest {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  headers?: object;
  body?: any;
  timeout?: number;
}

export interface DownloadFileRequest {
  url: string;
  method: "GET";
  headers?: object;
  fileName: string;
  progressCallback?: (event: any) => void;
}

export interface WebsiteApiJsonResponse {
  resultCode: number;
  errMessage?: string;
  data?: any;
}

export interface MarketplaceJsonResponse {
  code: number;
  error_message?: string;
  data?: any;
}

export const sendJsonRequest = async (request: JsonRequest): Promise<any> => {
  request.headers = Object.assign({}, request.headers || {}, {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    crossDomain: true,
  });
  try {
    axios.defaults.withCredentials = true;
    const response = await axios({
      ...request,
      responseType: "json",
      withCredentials: true,
      data: request.body ? JSON.stringify(request.body) : {},
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    }
    throw new NetworkError({
      message: "Cannot connect to server!",
      errorCode: ErrorCode.InternalServer,
    });
  }
};

export const sendWebsiteApiJsonRequest = async (
  request: JsonRequest,
): Promise<any> => {
  const response: WebsiteApiJsonResponse = await sendJsonRequest({
    ...request,
    url: WEBISTE_API_ENDPOINT + request.url,
  });
  if (!response || response.resultCode == null) {
    throw new ApiError({
      message: "Oops! Something went wrong!",
      errorCode: 500,
    });
  }
  if (response.resultCode >= 400) {
    throw new ApiError({
      message: response.errMessage || "",
      errorCode: response.resultCode,
    });
  }
  return response.data;
};

export const sendMarketplaceJsonRequest = async (
  request: JsonRequest,
): Promise<any> => {
  const response: MarketplaceJsonResponse = await sendJsonRequest({
    ...request,
    url: MARKETPLACE_ENDPOINT + request.url,
  });
  if (!response || response.code == null) {
    throw new ApiError({
      message: "Oops! Something went wrong!",
      errorCode: 500,
    });
  }
  if (response.code >= 400) {
    throw new ApiError({
      message: response.error_message || "",
      errorCode: response.code,
    });
  }
  return response.data;
};
