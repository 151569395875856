interface MenuItem {
  key: string;
  title: string;
  url?: string;
  exactMatch?: boolean;
  requireAuth?: boolean;
  requiredRole?: string;
  children?: {
    key: string;
    title: string;
    url?: string;
    exactMatch?: boolean;
    requireAuth?: boolean;
    requiredRole?: string;
  }[];
}

export const MENU_ITEMS: MenuItem[] = [
  {
    key: "download",
    title: "Download OpenChrom",
    url: "/download",
    children: [],
  },
];

export const BRANDS = [
  {
    title: 'chromtech',
    url: 'http://www.chromtech.de',
    image: require('../assets/images/brand-logos/chromtech.png'),
  },
  {
    title: 'gerstel',
    url: 'http://www.gerstel.de',
    image: require('../assets/images/brand-logos/gerstel.png'),
  },
  {
    title: 'hyperchrom',
    url: 'https://hyperchrom.com',
    image: require('../assets/images/brand-logos/hyperchrom.png'),
  },
  {
    title: 'mdctec',
    url: 'https://mdctec-systems.de',
    image: require('../assets/images/brand-logos/mdctec.png'),
  },
  {
    title: 'symrise',
    url: 'https://www.symrise.com',
    image: require('../assets/images/brand-logos/symrise.png'),
  },
  {
    title: 'now-foods',
    url: 'https://www.nowfoods.com',
    image: require('../assets/images/brand-logos/now-foods.png'),
  },
  {
    title: 'ladr',
    url: 'https://ladr.de',
    image: require('../assets/images/brand-logos/ladr.png'),
  },
  {
    title: 'gfl-berlin',
    url: 'https://www.gfl-berlin.com',
    image: require('../assets/images/brand-logos/gfl.png'),
    customStyle: { width: '100px' },
  },
  {
    title: 'lucidity',
    url: 'https://luciditysystems.com',
    image: require('../assets/images/brand-logos/lucidity.png'),
  },
  {
    title: 'pyrolyscience',
    url: 'https://pyrolyscience.com/',
    image: require('../assets/images/brand-logos/pyrolyscience.png'),
  },
  {
    title: 'helmholtz',
    url: 'https://www.helmholtz-muenchen.de/',
    image: require('../assets/images/brand-logos/helmholtz.png'),
  },
  {
    title: 'environmentalStandards',
    url: 'https://www.envstd.com',
    image: require('../assets/images/brand-logos/environmental-standards.png'),
  },
  {
    title: 'beiersdorf',
    url: 'https://www.beiersdorf.de',
    image: require('../assets/images/brand-logos/beiersdorf_logo_blue_rgb_grayscale.png'),
  },
];