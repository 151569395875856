import { createSelector } from "reselect";
import { AppState } from "../../reducers";
import { DownloadCategory } from "../../types/download";

const selectMarketplaceState = (state: AppState) => state.marketplace;

export const makeSelectMyDownloads = () =>
  createSelector([selectMarketplaceState], (marketplaceState) =>
    Object.values(marketplaceState.myDownloads),
  );

export const makeSelectProductDownloads = () =>
  createSelector(
    [selectMarketplaceState],
    (marketplaceState) =>
      Object.values(marketplaceState.myDownloads)
        .filter((download) => download.category === DownloadCategory.Product)
        .sort((a, b) => a.order - b.order), // asc
  );

export const makeSelectHashSizes = () =>
  createSelector([selectMarketplaceState], (marketplaceState) =>
    Object.values(marketplaceState.hashSizes),
  );
