import React from "react"
import Carousel, { CarouselProps } from "antd/lib/carousel"
import { BRANDS } from "../../constants/menu"
import "./index.css"

const Brands: React.FC = () => {
  const carouselProps: CarouselProps = {
    slidesToShow: 5,
    autoplay: true,
    dots: false,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          autoplay: true
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          autoplay: true
        }
      }
    ],
  };

  return (
    <div className="brands-container container mx-auto px-4 md:px-8 lg:px-12 py-6">
      <Carousel {...carouselProps} className="w-full middle">
        {BRANDS.map(brand => (
          <div key={brand.url} className="h-full px-6">
            <a href={brand.url} target="_blank" rel="noopener noreferrer" className="h-full flex flex-row items-center justify-center">
              <img src={brand.image} alt={brand.title} style={brand.customStyle ? brand.customStyle : {}} className="w-full h-auto block" />
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Brands;
