import uuid from "./utils/uuid";

export enum ErrorCode {
  NoError = 200,

  Unknown = 500,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  RequestTimeout = 408,
  UnsupportedMediaType = 415,
  Validation = 422,
  TooManyRequests = 429,
  InternalServer = 500,
  Maintenance = 503,
}

type ErrorOptions = {
  message?: string;
  statusCode?: number;
  errorCode?: number;
  level?: "normal" | "critical";
  hideStack?: boolean;
  details?: Object;
};

export class AppError extends Error {
  id: string;
  statusCode: number;
  errorCode: number;
  level: "normal" | "critical";
  hideStack: boolean;
  details: Object | null | undefined;

  static fromError(error: Error): AppError {
    if (error instanceof AppError) {
      return error;
    }

    const appError = error as AppError;

    appError.id = uuid();
    appError.statusCode = 500;
    appError.errorCode = ErrorCode.Unknown;
    appError.level = "normal";
    appError.details = undefined;
    appError.hideStack = false;

    return appError;
  }

  constructor(options: ErrorOptions = {}) {
    super(options.message);

    this.id = uuid();
    this.statusCode = options.statusCode || 500;
    this.errorCode = options.errorCode || ErrorCode.Unknown;
    this.level = options.level || "normal";
    this.details = options.details || undefined;
    this.hideStack = options.hideStack || false;
  }
}

export class NotFoundError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 404;
    options.errorCode = options.errorCode || ErrorCode.NotFound;
    super(options);
  }
}

export class BadRequestError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 400;
    options.errorCode = options.errorCode || ErrorCode.BadRequest;
    options.level = "critical";
    super(options);
  }
}

export class UnauthorizedError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 401;
    options.errorCode = options.errorCode || ErrorCode.Unauthorized;
    options.level = "critical";
    super(options);
  }
}

export class MaintenanceError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 503;
    options.errorCode = options.errorCode || ErrorCode.Maintenance;
    super(options);
  }
}

export class InternalServerError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 500;
    options.errorCode = options.errorCode || ErrorCode.InternalServer;
    options.level = "critical";
    super(options);
  }
}

export class ApiError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 200;
    options.errorCode = options.errorCode || ErrorCode.Unknown;
    super(options);
  }
}

export class NetworkError extends AppError {
  constructor(options: ErrorOptions = {}) {
    options.statusCode = 408;
    options.errorCode = options.errorCode || ErrorCode.RequestTimeout;
    super(options);
  }
}
