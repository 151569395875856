import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

class ScrollToTop extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter<Props, any>(ScrollToTop);
