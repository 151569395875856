import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { push } from "connected-react-router";
import { AppState, Dispatch } from "../../reducers";
import { ContainerProps, StateProps, makeSelectMenuItems } from "./selectors";
import { setActiveMenu } from "./actions";
import View from "./Header";
import { isAbsoluteUrl } from "../../utils/link";

const mapStateToProps = createStructuredSelector<
  AppState,
  ContainerProps,
  StateProps
>({
  menuItems: makeSelectMenuItems(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setActiveMenu,
      navigate: (url: string) => {
        if (isAbsoluteUrl(url)) {
          setTimeout(() => (window.location.href = url), 100);
          return { type: "" };
        }
        return push(url);
      },
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(View);
