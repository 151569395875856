import { Button, Select } from "antd";
import Checkbox from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Url from "../../components/Url";
import { Download, DownloadElement } from "../../types/download";
import "./Download.css";
import { ActionProps, ContainerProps, StateProps } from "./selectors";

import screenshot from "../../assets/images/screenshots/openchromdownload.png";
import { OS } from "../../constants/links";
import OpenChromDownloadLinux from "./OpenChromDownloadLinux";
import OpenChromDownloadMacOS from "./OpenChromDownloadMacOS";
import OpenChromDownloadWindow from "./OpenChromDownloadWindow";
import MarketplaceButton from "../../components/MarketplaceButton";

const { Option } = Select;

interface Props extends ContainerProps, StateProps, ActionProps { }

const OpenChromDownload: React.FC<Props> = (props) => {
  const [selectedDownloadId, setSelectedDownloadId] =
    useState<Download["id"]>();
  const [selectedElementId, setSelectedElementId] =
    useState<DownloadElement["id"]>();
  const [agree, setAgree] = useState(false);
  const [selectedOS, setSelectedOS] = useState<OS>(OS.None);

  useEffect(() => {
    props
      .getMyDownloads({ page: 1, pageSize: 99999, searchText: "" })
      .catch(console.error);
    props.getHashSize().catch(console.error);
  }, []);

  useEffect(() => {
    let selectedDownload = props.downloads.find(
      (download) => download.id === selectedDownloadId,
    );
    if (!selectedDownload) {
      if (!props.downloads[0]) {
        setSelectedDownloadId(undefined);
      } else {
        selectedDownload = props.downloads[0];
        setSelectedDownloadId(selectedDownload.id);
      }
      setSelectedElementId(undefined);
    }

    if (!selectedDownload) return;

    let selectedElement = selectedDownload.elements.find(
      (element) => element.id === selectedElementId,
    );
    if (!selectedElement) {
      if (!selectedDownload.elements[0]) {
        setSelectedElementId(undefined);
      } else {
        selectedElement = selectedDownload.elements[0];
        setSelectedElementId(selectedElement.id);
      }
    }
  }, [props.downloads]);

  useEffect(() => {
    if (isWindows()) {
      setSelectedOS(OS.Windows);
    } else if (isMacintosh()) {
      setSelectedOS(OS.MacOS);
    } else if (isLinux()) {
      setSelectedOS(OS.Linux);
    }
  }, [agree]);

  const selectDownload = (id: Download["id"]) => {
    setSelectedDownloadId(id);

    const selectedDownload = props.downloads.find(
      (download) => download.id === id,
    );
    if (!selectedDownload) return;
    if (!selectedDownload.elements[0]) {
      setSelectedElementId(undefined);
    } else {
      const selectedElement = selectedDownload.elements[0];
      setSelectedElementId(selectedElement.id);
    }
  };

  const selectElement = (id: DownloadElement["id"]) => {
    setSelectedElementId(id);
  };

  const agreeDownload = () => {
    setAgree(true);
  };

  const openDownload = (os: OS) => {
    setSelectedOS(os);
    const selectedDownload = props.downloads.find(
      (download) => download.id === selectedDownloadId,
    );
    if (!selectedDownload) return;
    const selectedElement = selectedDownload.elements.find(
      (element) => element.id === selectedElementId,
    );
    if (!selectedElement) return;
  };

  const selectedDownload = props.downloads.find(
    (download) => download.id === selectedDownloadId,
  );

  const getDownloadVersion = () => {
    const selectedDownload = props.downloads.find(
      (download) => download.id === selectedDownloadId,
    );
    if (!selectedDownload) return "";
    const selectedElement = selectedDownload.elements.find(
      (element) => element.id === selectedElementId,
    );
    if (!selectedElement) return "";
    return selectedElement.version;
  };

  const getDownloadUrl = () => {
    const selectedDownload = props.downloads.find(
      (download) => download.id === selectedDownloadId,
    );
    if (!selectedDownload) return "";
    const selectedElement = selectedDownload.elements.find(
      (element) => element.id === selectedElementId,
    );
    if (!selectedElement) return "";
    return selectedElement.url;
  };

  const isMacintosh = () => {
    return navigator.platform.indexOf("Mac") > -1;
  };

  const isWindows = () => {
    return navigator.platform.indexOf("Win") > -1;
  };

  const isLinux = () => {
    return navigator.platform.indexOf("Linux") > -1;
  };

  return (
    <div
      className=""
      style={{
        minHeight: "25rem",
        backgroundImage:
          "linear-gradient(to right, rgb(114, 114, 114), rgba(114, 114, 114, 0))",
      }}
    >
      <Helmet>
        <title> Download OpenChrom </title>
        <meta
          name="description"
          content="Download the latest version of OpenChrom - Open Source Software for Chromatography and Spectroscopy. Available for Windows, macOS and Linux."
        />
      </Helmet>
      <div className="container px-4 md:px-8 lg:px-12 mx-auto grid gap-2 md:gap-4 grid-cols-6 py-8">
        <div
          className="md:col-span-2 md:col-start-2 col-span-4 col-start-2"
          style={{ color: "#484848" }}
        >
          {/* Add h1 tag for SEO */}
          <h1 className="hidden"> Lablicate Download Openchrom </h1>
          <div className="text-2xl md:text-4xl font-bold leading-none">
            Download OpenChrom<sup>®</sup>
          </div>
          <div className="text-xl md:text-2xl font-bold leading-none mt-2 md:mt-4">
            Available for Windows, macOS and Linux.
          </div>

          <div className="mt-6 flex md:flex-row items-start flex-col">
            <Select<Download["id"]>
              className="w-64 btn-download"
              size="large"
              loading={!selectedDownloadId}
              value={selectedDownloadId}
              onSelect={selectDownload}
            >
              {props.downloads.map((download) => (
                <Option key={download.id} value={download.id}>
                  {download.title}
                </Option>
              ))}
            </Select>

            {selectedDownload && (
              <Select<DownloadElement["id"]>
                key={selectedDownload.id}
                className="w-32 md:ml-4 btn-download mt-4 md:mt-0"
                size="large"
                loading={!selectedElementId}
                value={selectedElementId}
                onSelect={selectElement}
              >
                {selectedDownload.elements.map((element) => (
                  <Option key={element.id} value={element.id}>
                    {element.version}
                  </Option>
                ))}
              </Select>
            )}
          </div>

          {!agree && (
            <div className="mt-4 text-base transform">
              <Checkbox onChange={agreeDownload}>
                I accept{" "}
                <Url
                  url="https://lablicate.com/about/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Privacy & Cookies</b>
                </Url>{" "}
                and{" "}
                <Url
                  url="https://lablicate.com/about/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Terms & Conditions</b>
                </Url>
                .
              </Checkbox>
            </div>
          )}

          {/*<div className="text-base mt-4 self-center md:self-start">
            * Only 64-bit is supported
          </div>*/}
        </div>

        <div className="md:col-span-2 col-span-4 col-start-2 my-2 md:my-0">
          <img src={screenshot} alt="OpenChrom" className="banner-image" />
        </div>
        {agree && (
          <div className="grid grid-cols-3 col-start-2 col-end-6 btn-download gap-4">
            {Object.values(OS)
              .filter((os) => os !== OS.None)
              .map((os) => (
                <Button
                  key={os}
                  ghost={os !== selectedOS}
                  className="btn-os-download h-12 lg:text-lg p-0 rounded"
                  onClick={() => openDownload(os)}
                >
                  {os}
                </Button>
              ))}
          </div>
        )}
      </div>
      {agree && selectedOS !== OS.None && (
        <div className="container px-4 md:px-8 lg:px-12 mx-auto pb-8">
          <div className="download-area h-auto p-4">
            {selectedOS === OS.Windows && (
              <OpenChromDownloadWindow
                hashSizes={props.hashSizes}
                version={getDownloadVersion()}
                url={getDownloadUrl()}
              />
            )}
            {selectedOS === OS.Linux && (
              <OpenChromDownloadLinux
                hashSizes={props.hashSizes}
                version={getDownloadVersion()}
                url={getDownloadUrl()}
              />
            )}
            {selectedOS === OS.MacOS && (
              <OpenChromDownloadMacOS
                hashSizes={props.hashSizes}
                version={getDownloadVersion()}
                url={getDownloadUrl()}
              />
            )}
            <div className="text-align-start text-lg pt-4">
              <p className="overflow-wrap-breakword">
                Check out the marketplace for further valuable extensions:
              </p>
              <MarketplaceButton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenChromDownload;
