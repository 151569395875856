import { faCheckDouble, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, notification } from "antd";
import React from "react";
import MarketplaceButton from "../../components/MarketplaceButton";
import NotificationSHA256 from "../../components/NotificationSHA256";
import {
  DOWNLOAD_OS_MAPPING,
  DOWNLOAD_PACKAGING_MAPPING,
  OPENCHROM_FILE_TEMPLATE,
  OS,
} from "../../constants/links";
import { HashSize } from "../../types/download";
import { getDownloadLinkByOS, getDownloadLinuxAarchLink } from "../../utils/link";

interface Props {
  hashSizes: HashSize[];
  version: string;
  url: string;
}

const OpenChromDownloadLinux: React.FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification();

  const getFileSize = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.size : "";
  };

  const getFileHash = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.hash : "";
  };

  const getFileName = () => {
    return OPENCHROM_FILE_TEMPLATE.replace(
      /{os}/g,
      DOWNLOAD_OS_MAPPING[OS.Linux],
    )
      .replace(/{version}/g, props.version)
      .replace(/{packaging}/g, DOWNLOAD_PACKAGING_MAPPING[OS.Linux]);
  };

  const getAarchFileName = () => {
    return (getFileName() || "").replace("x86_64", "aarch64");
  };

  const openDownload = async (fileName: string, downloadUrl: string) => {
    api.open({
      message: `SHA256 Hash for ${fileName}`,
      icon: <FontAwesomeIcon icon={faCheckDouble} />,
      description: <NotificationSHA256 hash={getFileHash(fileName)} />,
      duration: 0
    });

    window.open(downloadUrl, '_blank');
  };

  return (
    <>
      {contextHolder}
      <div className="flex flex-col lg:flex-row justify-around">
        <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
          <p className="overflow-wrap-breakword flex lg:justify-center">
            OpenChrom is available as a portable .tar.gz file
          </p>
          <div className="flex flex-col">
            <div className="ant-btn-group btn-download h-auto lg:m-4 m-1 text-lg justify-center">
              <Button
                type="default"
                key="k2"
                className="shadow text-lg flex h-auto p-4 mr-2"
                style={{ color: "white" }}
                onClick={() => openDownload(getFileName(), getDownloadLinkByOS(props.url, OS.Linux))}
              >
                <div>
                  <span className="font-semibold">Download .tar.gz file</span>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">File name:</span>{" "}
                    <span>{getFileName()}</span>
                  </div>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">Size:</span>{" "}
                    <span>{getFileSize(getFileName())}</span>
                  </div>
                </div>
                <div className="my-auto ml-4">
                  <FontAwesomeIcon size="2x" icon={faDownload} />
                </div>
              </Button>
            </div>
          </div>
        </div>
        {!!getFileHash(getAarchFileName()) && (
          <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
            <p className="overflow-wrap-breakword flex lg:justify-center">
              OpenChrom is also available for ARM64.
            </p>
            <div className="flex justify-center">
              <div className="ant-btn-group btn-download h-auto lg:m-4 m-1 text-lg">
                <Button
                  type="default"
                  key="k1"
                  className="shadow text-lg flex h-auto p-4"
                  style={{ color: "white" }}
                  onClick={() => openDownload(getAarchFileName(), getDownloadLinuxAarchLink(props.url, props.version))}
                >
                  <div>
                    <span className="font-semibold">Download ARM64 build</span>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">File name:</span>{" "}
                      <span>{getAarchFileName()}</span>
                    </div>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">Size:</span>{" "}
                      <span>{getFileSize(getAarchFileName())}</span>
                    </div>
                  </div>
                  <div className="my-auto ml-4">
                    <FontAwesomeIcon size="2x" icon={faDownload} />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-align-start text-lg">
        <p className="overflow-wrap-breakword">
          And via community repositories
        </p>
      </div>
      <div className="text-align-start text-lg ">
        <p className="overflow-wrap-breakword">
          <a href="https://flathub.org/apps/com.lablicate.OpenChrom">Flathub</a>:
        </p>
        <p className="lg:mt-4 mt-1">
          <code className="terminal">
            <span className="terminal__prompt">$</span> flatpak install flathub
            com.lablicate.OpenChrom
          </code>
        </p>
      </div>
      <div className="text-align-start text-lg mt-4">
        <p className="overflow-wrap-breakword">
          <a href="https://snapcraft.io/install/openchrom/ubuntu">Snap</a>:
        </p>
        <p className="lg:mt-4 mt-1">
          <code className="terminal">
            <span className="terminal__prompt">$</span> sudo snap install
            openchrom
          </code>
        </p>
      </div>
    </>
  );
};

export default OpenChromDownloadLinux;
