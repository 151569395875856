import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { push } from "connected-react-router";
import { AppState, Dispatch } from "../../reducers";
import { ContainerProps, StateProps } from "./selectors";
import {
  makeSelectProductDownloads,
  makeSelectHashSizes,
} from "../../containers/marketplace/selectors";
import {
  getMyDownloads,
  updateHashSize,
  getHashSize,
} from "../../containers/marketplace/actions";
import View from "./OpenChromDownload";

const mapStateToProps = createStructuredSelector<
  AppState,
  ContainerProps,
  StateProps
>({
  downloads: makeSelectProductDownloads(),
  hashSizes: makeSelectHashSizes(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigate: (url: string) => push(url),
      getMyDownloads,
      updateHashSize,
      getHashSize,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(View);
