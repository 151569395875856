import includes from "lodash/includes";
import { osName } from "react-device-detect";
import {
  DOWNLOAD_OS_MAPPING,
  DOWNLOAD_PACKAGING_MAPPING,
  DOWNLOAD_WS_MAPPING,
  OS,
  OS_MAPPING,
  OPENCHROM_FILE_NAME,
} from "../constants/links";

export const isAbsoluteUrl = (url: string): boolean =>
  !!url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );

export const getDownloadLinkByOS = (url: string, os?: OS) => {
  let detectedOS = OS.Windows;
  if (os && includes(Object.values(OS), os)) {
    detectedOS = os;
  } else {
    if (includes(OS_MAPPING[OS.Linux], osName)) {
      detectedOS = OS.Linux;
    } else if (includes(OS_MAPPING[OS.MacOS], osName)) {
      detectedOS = OS.MacOS;
    }
  }

  return url
    .replace("{os}", DOWNLOAD_OS_MAPPING[detectedOS])
    .replace("{packaging}", DOWNLOAD_PACKAGING_MAPPING[detectedOS])
    .replace("{ws}", DOWNLOAD_WS_MAPPING[detectedOS]);
};

export const getDownloadExeLink = (url: string, version: string, type: "setup" | "portable" = "portable") => {
  return url.replace(/openchrom-.*/g, `OpenChrom_${version}_${type}_x64.exe`);
};

export const getDownloadAarchLink = (url: string, version: string) => {
  return url.replace(/openchrom-.*/g, `OpenChrom_${version}_aarch64.dmg`);
};

export const getDownloadLinuxAarchLink = (url: string, version: string) => {
  return url.replace(/openchrom-.*/g, `openchrom-lablicate_linux.aarch64_${version}.tar.gz`);
};

export const getDownloadFileName = (os?: OS) => {
  let detectedOS = OS.Windows;
  if (os && includes(Object.values(OS), os)) {
    detectedOS = os;
  } else {
    if (includes(OS_MAPPING[OS.Linux], osName)) {
      detectedOS = OS.Linux;
    } else if (includes(OS_MAPPING[OS.MacOS], osName)) {
      detectedOS = OS.MacOS;
    }
  }
  return OPENCHROM_FILE_NAME[detectedOS];
};
