import React, { AnchorHTMLAttributes } from "react";
import classNames from "classnames";
import Url from "../Url";

const ChromIdentLinkableText: React.FC<
  AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  return (
    <Url
      {...props}
      className={classNames(props.className, "link font-semibold")}
      url="https://lablicate.com/extensions/chromident"
    >
      ChromIdent
    </Url>
  );
};

export default ChromIdentLinkableText;
