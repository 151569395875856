import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React from "react";

const MarketplaceButton: React.FC = (props) => {
  const onDownloadClick = () => {
    window.location.href = "https://lablicate.com/marketplace/categories";
  };

  return (
    <Button
      className="ant-btn ant-btn-default shadow text-lg flex h-full p-4 btn-green px-6 justify-center w-full mt-4"
      type="primary"
      size="large"
      onClick={onDownloadClick}
    >
      <div className="flex flex-col self-center">
        <div className="font-semibold">Marketplace</div>
        <div>Platform Extensions</div>
      </div>
      <div className="my-auto ml-4">
        <FontAwesomeIcon icon={faWarehouse} className="" size="2x" />
      </div>
    </Button>
  );
};

export default MarketplaceButton;
