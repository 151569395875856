import React from "react";
import Lightbox from "react-image-lightbox";

interface Props {
  images: string[];
  selectedIndex?: number;
  setSelectedIndex: (index: number) => void;
}

const ImageSlider: React.FC<Props> = (props) => {
  if (props.selectedIndex == null || props.selectedIndex === -1) return null;

  const onCloseRequest = () => {
    props.setSelectedIndex(-1);
  };

  const onMovePrevRequest = () => {
    props.setSelectedIndex(
      (props.selectedIndex! + props.images.length - 1) % props.images.length,
    );
  };

  const onMoveNextRequest = () => {
    props.setSelectedIndex((props.selectedIndex! + 1) % props.images.length);
  };

  return (
    <Lightbox
      mainSrc={props.images[props.selectedIndex]}
      nextSrc={props.images[(props.selectedIndex + 1) % props.images.length]}
      prevSrc={
        props.images[
        (props.selectedIndex + props.images.length - 1) % props.images.length
        ]
      }
      imagePadding={40}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  );
};

export default ImageSlider;
