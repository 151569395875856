import React, { useContext } from "react";
import {
  connect,
  ReactReduxContext,
  ReactReduxContextValue,
} from "react-redux";
import { AppState, Dispatch } from "./reducers";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Download from "./pages/download";
import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import "./Root.css";
import "./tailwind.css";
import 'react-image-lightbox/style.css';
import { Layout, BackTop } from "antd";

interface ContainerProps { }

interface StateProps { }

interface ActionProps { }

interface Props
  extends RouteComponentProps,
  ContainerProps,
  StateProps,
  ActionProps { }

const {
  Header: LayoutHeader,
  Footer: LayoutFooter,
  Content: LayoutContent,
} = Layout;

const Root: React.FC<Props> = (props) => {
  const reduxContext =
    useContext<ReactReduxContextValue<AppState>>(ReactReduxContext);

  return (
    <div className="app-container h-full">
      <ScrollToTop>
        <Layout className="h-full">
          <LayoutHeader
            id="app-header"
            className="app-header -sticky top-0 z-50"
          >
            <Header location={props.location} />
          </LayoutHeader>

          <LayoutContent
            className="flex-shrink-0"
            style={{ minHeight: "25rem" }}
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/download" component={Download} />
            </Switch>
          </LayoutContent>

          <LayoutFooter className="ms-footer mt-auto p-0">
            <Footer />
          </LayoutFooter>
        </Layout>
      </ScrollToTop>

      <BackTop />
    </div>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  ContainerProps,
  StateProps
>({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Root));
